import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ApiService } from 'src/app/cooldash/services/api/api.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  isSubmitted: boolean = false;
  email = new FormControl('', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'), Validators.required]);

  constructor(private api: ApiService,private toastr: ToastrManager,private router: Router) { }

  ngOnInit() { }

  close() {
    var data = {
      response: "no",
      email: this.email
    };
    // this.dialog.close(data);
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.email.valid && this.isSubmitted) {
      var data = {
        // response: "yes",
        email: this.email.value
      };
      this.api.forgotPasswordSendEmail(data).subscribe(res => {
        if (res["response"]["success"]) {
          this.toastr.successToastr(res["response"]["message"]);
          this.router.navigateByUrl('login');
        } else {
          this.toastr.errorToastr(res["response"]["message"]);
        }
      })
    }
  }
}


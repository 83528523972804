import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { CommonService } from "../../../../services/common/common.service";
import { ApiService } from "../../../../services/api/api.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { ActivatedRoute } from "@angular/router";

declare var $: any;
@Component({
  selector: "app-categorymodal",
  templateUrl: "./categorymodal.component.html",
  styleUrls: ["./categorymodal.component.scss"],
})
export class CategorymodalComponent implements OnInit {
  categoryForm: FormGroup;
  categoryImage: any;
  File;
  id: any;
  submitted: boolean = false;
  item: any;
  url: string;
  VT: any;
  currentModule;
  local: any;
  constructor(
    private dialog: MatDialogRef<CategorymodalComponent>,
    private formBuilder: FormBuilder,
    public comm: CommonService,
    public api: ApiService,
    public toastr: ToastrManager,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((res) => {
      console.log("res", res);
      this.VT = parseInt(res.VT);
    });
    if (location.href.indexOf("rfc") !== -1) {
      this.url = "resturantTag";
    } else {
      this.url = "category";
    }
  }

  ngOnInit() {
    this.categoryForm = this.formBuilder.group({
      name: new FormControl("", [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(".*\\S.*[a-zA-z0-9 ]"),
      ]),
    });
    if (this.item) {
      console.log(this.item);
      this.setValues();
    }
    if (this.currentModule !== "category") {
      this.local = "Type";
    } else {
      this.local = "Category";
    }
  }

  async profilePic(event) {
    this.File = event.target.files[0];
    if (this.File) {
      if(this.File.type == "image/jpeg" || this.File.type == "image/jpg" || this.File.type == "image/png")
      {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.categoryImage = event.target.result;
      };
  }
  else{
    this.comm.errorToast(
      "Category image should be in jpg , jpeg and png format only"
    );
    this.File = '';
  }
}
  }

  setValues = () => {
    if (this.item) {
      this.categoryForm.patchValue({
        name: this.item.name,
      });
      if (this.item.image) {
        this.categoryImage = this.comm.imageUrl + this.item.image;
      }
    }
  };

  onSubmit() {
    console.log(this.url);
    this.submitted = true;
    if (this.File) {
      if (this.categoryForm.valid && this.submitted) {
        var data = {
          name: this.categoryForm.controls["name"].value.trim(),
          categoryType: parseInt(localStorage.getItem("VT")),
        };
        let formData = new FormData();
        formData.append("data", JSON.stringify(data));
        formData.append("image", this.File);
        this.api.addFoodCategory(formData, this.url).subscribe((res) => {
          if (res["response"]["success"]) {
            this.toastr.successToastr(res["response"]["message"]);
            this.dialog.close("yes");
          } else {
            this.toastr.errorToastr(res["response"]["message"]);
            this.dialog.close("no");
          }
        });
      }
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.categoryForm.controls[control].hasError(error);
  };

  close() {
    this.dialog.close();
  }

  onUpdate() {
    this.submitted = true;
    if (this.File || this.item.image) {
      if (this.categoryForm.valid && this.submitted) {
        var data = {
          name: this.categoryForm.controls["name"].value.trim(),
          updateId: this.item._id,
        };
        let formData = new FormData();
        formData.append("data", JSON.stringify(data));
        if (this.File) {
          formData.append("image", this.File);
        } else {
          formData.append("image", this.item.image);
        }
        this.api.editFoodCategory(formData, this.url).subscribe((res) => {
          if (res["response"]["success"]) {
            this.toastr.successToastr(res["response"]["message"]);
            this.dialog.close("yes");
          } else {
            this.toastr.errorToastr(res["response"]["message"]);
            this.dialog.close("no");
          }
        });
      }
    }
  }
}

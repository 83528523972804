import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { UrlService } from "../url/url.service";
import { LoginBody } from "../../requests/login-body";
import { map } from "rxjs/operators";
import { CommonService } from "../common/common.service";
import { idLocale } from "ngx-bootstrap";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  countryCode: any;

  constructor(
    private http: HttpClient,
    private url: UrlService,
    private comm: CommonService
  ) {
    this.getCountryCode();
  }

  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map((response) => response));
  }

  singIn(body: LoginBody) {
    return this.http.post(this.comm.baseUrl + "/admin/login", body);
  }

  checkToken(body) {
    return this.http.post(this.comm.baseUrl + "/admin/checkToken", body);
  }

  forgotPasswordSendEmail(body) {
    return this.http.post(this.comm.baseUrl + "/admin/forgotPassword", body);
  }

  resetPassword(body, endPoint) {
    return this.http.post(this.comm.baseUrl + endPoint, body);
  }

  sendToken(token: string) {
    localStorage.setItem("token", token);
  }
  getToken() {
    return localStorage.getItem("token");
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }
  // getReview(url) {
  //   return this.http.get(this.comm.baseUrl + "/admin/food/"+url);
  // }

  getAllRestaurantCategories(url, cattype) {
    return this.http.get(
      this.comm.baseUrl + "/admin/food/" + url + "?categoryType=" + cattype
    );
  }

  addFoodCategory(body, url) {
    return this.http.post(this.comm.baseUrl + "/admin/food/" + url, body);
  }
  addFoodCategoryII(body, url) {
    return this.http.post(this.comm.baseUrl + "/admin/food/" + url, body);
  }

  editFoodCategory(body, url) {
    return this.http.put(this.comm.baseUrl + "/admin/food/" + url, body);
  }

  getAllRestaurant(search, type, page, cattype, restaurantTags) {
    return this.http.get(
      this.comm.baseUrl +
        "/admin/food/restaurant?name=" +
        search +
        "&type=" +
        type +
        "&page=" +
        page +
        "&categoryType=" +
        cattype +
        (restaurantTags ? "&resturantTags=" + restaurantTags : "")
    );
  }

  getAllRestaurants(search, type, page) {
    return this.http.get(
      this.comm.baseUrl +
        "/admin/food/getAllRestaurants" +
        (search ? "?name=" + search : "")
    );
  }

  editFoodRestaurant(body) {
    return this.http.put(this.comm.baseUrl + "/admin/food/restaurant", body);
  }

  editDriverDocument(body, id) {
    return this.http.put(
      this.comm.baseUrl + "/admin/driver/document/" + id,
      body
    );
  }

  header() {
    if (localStorage.token != undefined || localStorage.token != null) {
      const headers = new HttpHeaders({
        // 'cache-control': 'no-cache',
        // 'content-type': 'application/json',
        authorization: "ADMIN " + localStorage.token,
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  sendNotification(data) {
    return this.http.post(this.comm.baseUrl + "/admin/sendNotification", data);
  }

  getBanner(page, name, id) {
    return this.http.get(
      this.comm.baseUrl +
        "/admin/banner" +
        (page ? "?page=" + page : "") +
        (name ? "&name=" + name : "") +
        (id ? "/" + id : ""),
      this.header()
    );
  }
  addBanner(data) {
    return this.http.post(this.comm.baseUrl + "/admin/banner", data);
  }
  updateBanner(data) {
    return this.http.put(this.comm.baseUrl + "/admin/banner", data);
  }

  getOutlets(type) {
    return this.http.post(
      this.comm.baseUrl + "/admin/getOutlets",
      { type: type },
      this.header()
    );
  }

  getItems(type) {
    return this.http.post(
      this.comm.baseUrl + "/admin/getItmes",
      { type: type },
      this.header()
    );
  }

  getResturantById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/food/restaurant/" + id);
  }

  getResturantFoodItemById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/food/itembyid/" + id);
  }

  getAdminModules() {
    return this.http.get(this.comm.baseUrl + "/admin/getAdminModules");
  }

  addSubAdmin(data) {
    return this.http.post(this.comm.baseUrl + "/admin/subAdmin", data);
  }
  updateSubAdmin(data) {
    return this.http.put(this.comm.baseUrl + "/admin/subAdmin", data);
  }
  getAllSubAdmin(data) {
    return this.http.get(
      this.comm.baseUrl + "/admin/subAdmin" + (data.id ? "/" + data.id : ""),
      data
    );
  }
  deleteSubAdmin(data) {
    return this.http.delete(this.comm.baseUrl + "/admin/subAdmin", data);
  }

  getDashboardStats(data) {
    return this.http.post(this.comm.baseUrl + "/admin/getDashboardStats", data);
  }
  getDashboardStatsDetail(page, data) {
    return this.http.post(
      this.comm.baseUrl + "/admin/getDashboardStatsDetail?&page=" + page,
      data
    );
  }

  addAccessModules(data) {
    return this.http.post(this.comm.baseUrl + "/admin/accessModule", data);
  }

  editAccessModules(data) {
    return this.http.put(this.comm.baseUrl + "/admin/accessModule", data);
  }

  deleteAccessModules(param) {
    return this.http.delete(this.comm.baseUrl + "/admin/accessModule", param);
  }

  getAccessModuleId(id) {
    return this.http.get(this.comm.baseUrl + "/admin/getAccessModuleId/" + id);
  }

  getAllAccessModules() {
    return this.http.get(this.comm.baseUrl + "/admin/accessModule");
  }

  getStoreItemById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/store/itembyid/" + id);
  }

  addFoodRestaurant(body) {
    return this.http.post(this.comm.baseUrl + "/admin/food/restaurant", body);
  }

  adddriver(body) {
    return this.http.post(this.comm.baseUrl + "/driver/register", body);
  }

  getAllOrders(page, userId, cattype) {
    return this.http.get(
      this.comm.baseUrl +
        "/admin/food/orders?&page=" +
        page +
        (userId ? "&userId=" + userId : "") +
        "&categoryType=" +
        cattype
    );
  }

  getPendingOrders(status, page, userId, cattype) {
    return this.http.get(
      this.comm.baseUrl +
        "/admin/food/orders?status=" +
        status +
        "&page=" +
        page +
        (userId ? "&userId=" + userId : "") +
        "&categoryType=" +
        cattype
    );
  }

  getOutletById(id) {
    return this.http.get(
      this.comm.baseUrl + "/admin/food/restaurant/outlet/" + id
    );
  }

  getStoreOutletById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/store/outlet/" + id);
  }

  addRestaurantOutlet(body) {
    return this.http.post(
      this.comm.baseUrl + "/admin/food/restaurant/outlet",
      body
    );
  }

  editRestaurantOutlet(body) {
    return this.http.put(
      this.comm.baseUrl + "/admin/food/restaurant/outlet",
      body
    );
  }

  getPendingStoreOrders(status, page) {
    return this.http.get(
      this.comm.baseUrl +
        "/admin/store/orders/all?status=" +
        status +
        "&page=" +
        page
    );
  }

  getAllStoreOrders(status, page, userId) {
    return this.http.get(
      this.comm.baseUrl +
        "/admin/store/orders/all?&page=" +
        page +
        (userId ? "&userId=" + userId : "")
    );
  }

  getRestaurantTypeById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/food/type/" + id);
  }

  getRestaurantItemeById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/food/item/" + id);
  }

  getAllStoreCategories(url) {
    return this.http.get(this.comm.baseUrl + "/admin/store/" + url);
  }

  addStoreCategory(body, url) {
    return this.http.post(this.comm.baseUrl + "/admin/store/" + url, body);
  }

  editStoreCategory(body, url) {
    return this.http.put(this.comm.baseUrl + "/admin/store/" + url, body);
  }

  getAllStore(search, page) {
    return this.http.get(
      this.comm.baseUrl + "/admin/store?page=" + page + "&name=" + search
    );
  }

  getAllStores(search, page) {
    return this.http.get(
      this.comm.baseUrl +
        "/store/getAllStores" +
        (page ? "?page=" + page : "") +
        (search ? "&name=" + search : "")
    );
  }

  getStoreById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/store/" + id);
  }

  addStore(body) {
    return this.http.post(this.comm.baseUrl + "/admin/store", body);
  }

  editStore(body) {
    return this.http.put(this.comm.baseUrl + "/admin/store", body);
  }

  addStoreOutlet(body) {
    return this.http.post(this.comm.baseUrl + "/admin/store/outlet", body);
  }

  editStoreOutlet(body) {
    return this.http.put(this.comm.baseUrl + "/admin/store/outlet", body);
  }

  addRestaurantFoodType(body) {
    return this.http.post(this.comm.baseUrl + "/admin/food/type", body);
  }

  addRestaurantFoodItem(body) {
    return this.http.post(this.comm.baseUrl + "/admin/food/item", body);
  }

  editRestaurantFoodType(body) {
    return this.http.put(this.comm.baseUrl + "/admin/food/type", body);
  }

  editRestaurantFoodItem(body) {
    return this.http.put(this.comm.baseUrl + "/admin/food/item", body);
  }

  addStoreFoodType(body) {
    return this.http.post(this.comm.baseUrl + "/admin/store/type", body);
  }

  addStoreFoodItem(body) {
    return this.http.post(this.comm.baseUrl + "/admin/store/item", body);
  }

  editStoreFoodType(body) {
    return this.http.put(this.comm.baseUrl + "/admin/store/type", body);
  }

  editStoreFoodItem(body) {
    return this.http.put(this.comm.baseUrl + "/admin/store/item", body);
  }

  getStoreTypeById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/store/type/" + id);
  }

  getStoreItemeById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/store/item/" + id);
  }

  addRestaurantSettings(body) {
    return this.http.post(this.comm.baseUrl + "/admin/food/setting", body);
  }

  editRestaurantSettings(body) {
    return this.http.put(this.comm.baseUrl + "/admin/food/setting", body);
  }

  getRestaurantSettings() {
    return this.http.get(this.comm.baseUrl + "/admin/food/setting");
  }

  addStoreSettings(body) {
    return this.http.post(
      this.comm.baseUrl + "/admin/store/common/setting",
      body
    );
  }

  editStoreSettings(body) {
    return this.http.put(
      this.comm.baseUrl + "/admin/store/common/setting",
      body
    );
  }

  getStoreSettings() {
    return this.http.get(this.comm.baseUrl + "/admin/store/common/setting");
  }

  getDriverList(page, name) {
    return this.http.get(
      this.comm.baseUrl + "/admin/driver/all?page=" + page + "&name=" + name
    );
  }

  getDriverDocument(id) {
    return this.http.get(this.comm.baseUrl + "/admin/driver/document/" + id);
  }

  updateDriverDocument(body, id) {
    return this.http.put(
      this.comm.baseUrl + "/admin/driver/document/" + id,
      body
    );
  }

  verifyDocument(body) {
    return this.http.put(
      this.comm.baseUrl + "/admin/driver/verifydocument",
      body
    );
  }

  updateDriver(body, id) {
    return this.http.put(
      this.comm.baseUrl + "/admin/driver/detail/" + id,
      body
    );
  }

  getStoreOrderDetail(id) {
    return this.http.get(this.comm.baseUrl + "/admin/store/order/" + id);
  }

  getRestaurantOrderDetail(id, cattype) {
    return this.http.get(
      this.comm.baseUrl + "/admin/food/order/" + id + "?categoryType=" + cattype
    );
  }

  getDriverById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/driver/detail/" + id);
  }

  getAllVerticleTypes(type) {
    return this.http.get(
      this.comm.baseUrl + "/driver/vehicletypes?type=" + type
    );
  }

  addDriverDocuments(body, id) {
    return this.http.post(
      this.comm.baseUrl + "/admin/driver/document/" + id,
      body
    );
  }

  getAllVehicle() {
    return this.http.get(this.comm.baseUrl + "/admin/vehicletype");
  }

  updateVehicle(body) {
    return this.http.put(this.comm.baseUrl + "/admin/vehicletype", body);
  }

  addVehicle(body) {
    return this.http.post(this.comm.baseUrl + "/admin/vehicletype", body);
  }

  getAllPromoCode() {
    return this.http.get(this.comm.baseUrl + "/admin/promocode");
  }

  updatePromoCode(body) {
    return this.http.put(this.comm.baseUrl + "/admin/promocode", body);
  }

  addPromoCode(body) {
    return this.http.post(this.comm.baseUrl + "/admin/promocode", body);
  }

  getPromocodeById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/promocode/" + id);
  }

  getAllUser(page, name) {
    return this.http.get(
      this.comm.baseUrl + "/admin/user?page=" + page + "&name=" + name
    );
  }

  updateUser(body) {
    return this.http.put(this.comm.baseUrl + "/admin/user", body);
  }

  addUser(body) {
    return this.http.post(this.comm.baseUrl + "/admin/user", body);
  }

  getUserById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/user/" + id);
  }

  getVendersList() {
    return this.http.get(this.comm.baseUrl + "/shuttle/vender/getall");
  }

  getAllVenders(page, searchText) {
    return this.http.get(
      this.comm.baseUrl +
        "/shuttle/vender/get?page=" +
        page +
        "&text=" +
        searchText
    );
  }

  getVenderById(id) {
    return this.http.get(this.comm.baseUrl + "/shuttle/vender/get/" + id);
  }

  updateVender(id, body) {
    return this.http.put(
      this.comm.baseUrl + "/shuttle/vender/update/" + id,
      body
    );
  }

  deleteVender(id) {
    return this.http.put(
      this.comm.baseUrl + "/shuttle/vender/delete/" + id,
      {}
    );
  }

  addVender(body) {
    return this.http.post(this.comm.baseUrl + "/shuttle/vender/add", body);
  }

  getAllShuttle(page) {
    return this.http.get(this.comm.baseUrl + "/shuttle/getAll?page=" + page);
  }

  getShuttleById(id) {
    return this.http.get(this.comm.baseUrl + "/shuttle/getById?Id=" + id);
  }

  updateShuttle(id, body) {
    return this.http.put(this.comm.baseUrl + "/shuttle/update/" + id, body);
  }

  deleteShuttle(id, body) {
    return this.http.put(this.comm.baseUrl + "/shuttle/delete?Id=" + id, body);
  }

  addShuttle(body) {
    return this.http.post(this.comm.baseUrl + "/shuttle/add", body);
  }

  getAllShuttleLocation(page) {
    return this.http.get(
      this.comm.baseUrl + "/shuttle/location/getAll?page=" + page
    );
  }

  getAllShuttleLocationAdmin() {
    return this.http.get(this.comm.baseUrl + "/shuttle/location/getAll/admin");
  }

  updateShuttleLocation(id, body) {
    return this.http.put(
      this.comm.baseUrl + "/shuttle/location/update/" + id,
      body
    );
  }

  deleteShuttleLocation(body) {
    return this.http.put(this.comm.baseUrl + "/shuttle/location/delete", body);
  }

  addShuttleLocation(body) {
    return this.http.post(this.comm.baseUrl + "/shuttle/location/add", body);
  }

  getAllShuttleRoute(page) {
    return this.http.get(
      this.comm.baseUrl + "/shuttle/route/getAll?page=" + page
    );
  }

  getAllFreeRoute(id) {
    return this.http.get(
      this.comm.baseUrl + "/shuttle/route/shuttlefree/" + id
    );
  }

  updateShuttleRoute(id, body) {
    return this.http.put(
      this.comm.baseUrl + "/shuttle/route/update/" + id,
      body
    );
  }

  deleteShuttleRoute(body) {
    return this.http.put(this.comm.baseUrl + "/shuttle/route/delete", body);
  }

  addShuttleRoute(body) {
    return this.http.post(this.comm.baseUrl + "/shuttle/route/add", body);
  }

  getShuttleRouteById(id) {
    return this.http.get(this.comm.baseUrl + "/shuttle/route/getById?Id=" + id);
  }

  getAllShuttleDriver(id) {
    return this.http.get(this.comm.baseUrl + "/admin/driver/all/shuttle/" + id);
  }

  getDashboard(data) {
    return this.http.post(this.comm.baseUrl + "/admin/dashboard", data);
  }

  getRestaurantBookingAnalytic() {
    return this.http.get(this.comm.baseUrl + "/admin/revenue/restaurant");
  }

  getRevenueTaxi() {
    return this.http.get(this.comm.baseUrl + "/admin/revenue/taxi");
  }

  getRevenueStore() {
    return this.http.get(this.comm.baseUrl + "/admin/revenue/store");
  }

  getRevenueShuttle() {
    return this.http.get(this.comm.baseUrl + "/admin/revenue/shuttle");
  }

  getTaxiBooking(page, search, startDate, endDate) {
    return this.http.get(
      this.comm.baseUrl +
        "/admin/taxi/bookings?page=" +
        page +
        "&name=" +
        search +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }

  getTaxiBookingById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/taxi/booking/" + id);
  }

  getAllBookings(page, searhType, searchText) {
    return this.http.get(
      this.comm.baseUrl +
        "/shuttle/booking/admin?page=" +
        page +
        "&searchType=" +
        searhType +
        "&searchText=" +
        searchText
    );
  }

  getShuttleBookingDetail(id) {
    return this.http.get(this.comm.baseUrl + "/shuttle/booking/admin/" + id);
  }
  cancelBookings(id, data) {
    return this.http.put(
      this.comm.baseUrl + "/shuttle/booking/admin/cancel/" + id,
      data
    );
  }
  getAllShuttleOverview(page, date) {
    return this.http.get(
      this.comm.baseUrl +
        "/shuttle/booking/seatstats?page=" +
        page +
        "&date=" +
        date
    );
  }

  changePassword(body) {
    return this.http.post(this.comm.baseUrl + "/admin/adminChangePass", body);
  }

  getAdminProfile(id) {
    return this.http.get(this.comm.baseUrl + "/admin/getAdminProfile/" + id);
  }

  updateProfile(body) {
    return this.http.put(this.comm.baseUrl + "/admin/updateProfile", body);
  }

  getCrm(id) {
    return this.http.get(this.comm.baseUrl + "/admin/getCrm/" + id);
  }

  addCrm(body) {
    return this.http.post(this.comm.baseUrl + "/admin/addCrm", body);
  }

  updateAdminSettings(data) {
    return this.http.post(this.comm.baseUrl + "/admin/setSetting", data);
  }

  getAdminSettings(id) {
    console.log(this.comm.baseUrl + "/admin/getSetting/" + id);
    return this.http.get(this.comm.baseUrl + "/admin/getSetting/" + id);
  }

  getAddOns(id) {
    return this.http.get(this.comm.baseUrl + "/admin/getAddOns/" + id);
  }

  getAddOnsByName(id, name) {
    return this.http.get(
      this.comm.baseUrl + "/admin/getAddOns/" + id + "?name=" + name
    );
  }

  getAddOnById(id) {
    return this.http.get(this.comm.baseUrl + "/admin/getAddOnById/" + id);
  }

  addAddOn(body) {
    return this.http.post(this.comm.baseUrl + "/admin/addOns", body);
  }

  deleteAddOn(id) {
    return this.http.delete(this.comm.baseUrl + "/admin/deleteAddOns/" + id);
  }

  activeInactiveAddOn(body) {
    return this.http.put(this.comm.baseUrl + "/admin/addOns", body);
  }

  editAddOn(body) {
    return this.http.put(this.comm.baseUrl + "/admin/addOns", body);
  }

  getAllRestaurnentExport(body, type) {
    return this.http.post(
      this.comm.baseUrl + `/admin/getAllRestaurnentExport?type=${type}`,
      body
    );
  }
  getAllStoreExport(body) {
    return this.http.post(this.comm.baseUrl + "/admin/getAllStoreExport", body);
  }
  getAllUserExport(body) {
    return this.http.post(this.comm.baseUrl + "/admin/getAllUserExport", body);
  }
  getAllDriverExport(body) {
    return this.http.post(
      this.comm.baseUrl + "/admin/getAllDriverExport",
      body
    );
  }
  getAllStoreOrdersDetailsExport(body) {
    return this.http.post(
      this.comm.baseUrl + "/admin/getAllStoreOrdersDetailsExport",
      body
    );
  }
  getAllRestaurantOrdersDetailsExport(body) {
    return this.http.post(
      this.comm.baseUrl + "/admin/getAllRestaurantOrdersDetailsExport",
      body
    );
  }
}

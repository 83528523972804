import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { CommonService } from "../../../../services/common/common.service";
import { ApiService } from "../../../../services/api/api.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { MapsAPILoader } from "@agm/core";
import { BehaviorSubject } from "rxjs";
declare const google: any;

declare var $: any;
@Component({
  selector: "app-outletmodal",
  templateUrl: "./outletmodal.component.html",
  styleUrls: ["./outletmodal.component.scss"],
})
export class OutletmodalComponent implements OnInit {
  outletForm: FormGroup;
  item: any;
  restaurantId;
  submitted: boolean = false;
  updateAddrssStatus: boolean = false;
  updateAddress: any;
  latitude: number;
  longitude: number;
  lat: number = 10.7130193;
  lng: number = 36.7092518;
  addressLocation: any;
  geoCoder: any;
  geofenceDataArr = new BehaviorSubject<any>(null);
  map: any;
  polygon: any;
  drawingManager: any;
  pointList: { lat: number; lng: number }[] = [];
  selectedArea: number;
  countryRestriction = {
    latLngBounds: {
      west: 68.1866760254,
      east: 97.4033126831,
      north: 35.5042304993,
      south: 6.74713850021,
    },
    strictBounds: true,
  };
  zoom: number = 8;
  constructor(
    private dialog: MatDialogRef<OutletmodalComponent>,
    private formBuilder: FormBuilder,
    public comm: CommonService,
    public api: ApiService,
    public toastr: ToastrManager,
    private mapsAPILoader: MapsAPILoader
  ) {}

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.outletForm = this.formBuilder.group({
      address: new FormControl("", Validators.compose([Validators.required])),
      restaurantId: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      latitude: new FormControl("", Validators.compose([Validators.required])),
      longitude: new FormControl("", Validators.compose([Validators.required])),
    });
    if (this.item) {
      // this.lat = this.item.geoLongLat.coordinates[0][0][1]
      // this.lng = this.item.geoLongLat.coordinates[0][0][0]
      let arr = [this.item];
      this.geofenceDataArr.next(arr);
      this.setValues();
    } else {
      this.setCurrentLocation();
    }
  }

  onMapReady(map) {
    this.map = map;

    this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.geofenceDataArr &&
      this.geofenceDataArr.subscribe((res) => {
        if (res != null) {
          if (res.length > 0) {
            var triangleCoords = [];
            var empty = new google.maps.Polygon(null);
            empty.setMap(map);
            res.forEach((element) => {
              element.geoLongLat.coordinates[0].forEach((cords) => {
                this.lat = cords[1];
                this.lng = cords[0];
                triangleCoords.push(new google.maps.LatLng(cords[1], cords[0]));
              });
              this.polygon = new google.maps.Polygon({
                paths: triangleCoords,
                draggable: false,
                editable: true,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
              });
              this.polygon.setMap(map);
            });
          }
        }
      });
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event: {
        type: string;
        overlay: { getPaths: () => any; drag: any; getPath: () => any };
      }) => {
        if (event.type === "polygon") {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(paths.getAt(p), "set_at", () => {
              if (!event.overlay.drag) {
                self.updatePointList(event.overlay.getPath());
              }
            });
            google.maps.event.addListener(paths.getAt(p), "insert_at", () => {
              self.updatePointList(event.overlay.getPath());
            });
            google.maps.event.addListener(paths.getAt(p), "remove_at", () => {
              self.updatePointList(event.overlay.getPath());
            });
          }
          self.updatePointList(event.overlay.getPath());
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
      }
    );
  }

  updatePointList(path: {
    getLength: () => any;
    getAt: (
      arg0: number
    ) => {
      (): any;
      new (): any;
      toJSON: { (): { lat: number; lng: number }; new (): any };
    };
  }) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(path.getAt(i).toJSON());
    }
    console.log(this.pointList);
    this.selectedArea = google.maps.geometry.spherical.computeArea(path);
  }

  setCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 11;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        this.addressLocation = results[0].formatted_address;
        this.outletForm.patchValue({
          address: this.addressLocation,
          latitude: latitude,
          longitude: longitude,
        });
        console.log(this.addressLocation);
      }
    );
  }

  setValues = () => {
    if (this.item) {
      this.outletForm.patchValue({
        address: this.item.address,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        restaurantId: this.item.restaurantId,
      });
    }
    (this.latitude = this.item.latitude),
      (this.longitude = this.item.longitude),
      (this.lat = this.item.latitude),
      (this.lng = this.item.longitude),
      (this.updateAddress = this.outletForm.controls["address"].value);
  };

  onSubmit() {
    this.submitted = true;
    this.outletForm.controls["restaurantId"].setValue(this.restaurantId);
    var newArr = [];
    for (let i = 0; i < this.pointList.length; i++) {
      if (this.pointList.length - 1 === i) {
        newArr.push([this.pointList[i].lng, this.pointList[i].lat]);
        newArr.push([this.pointList[0].lng, this.pointList[0].lat]);
      } else {
        newArr.push([this.pointList[i].lng, this.pointList[i].lat]);
      }
    }
    let data = this.outletForm.value;
    data["geoLongLat"] = newArr;
    if (
      this.outletForm["controls"].latitude.value !== "" &&
      this.outletForm["controls"].latitude.value !== null &&
      this.outletForm["controls"].longitude.value !== "" &&
      this.outletForm["controls"].longitude.value !== null
    ) {
      if (this.submitted && this.outletForm.valid) {
        this.api.addRestaurantOutlet(data).subscribe((res) => {
          if (res["response"]["success"]) {
            this.toastr.successToastr(res["response"]["message"]);
            this.dialog.close("yes");
          } else {
            this.toastr.errorToastr(res["response"]["message"]);
            this.dialog.close("no");
          }
        });
      }
    } else if (this.outletForm["controls"].address.value) {
      this.toastr.errorToastr(
        "Please select a valid address from suggestions."
      );
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.outletForm.controls[control].hasError(error);
  };

  close() {
    this.dialog.close();
  }

  onUpdate() {
    this.submitted = true;
    this.outletForm.controls["restaurantId"].setValue(this.item.restaurantId);
    if (this.outletForm.valid && this.submitted) {
      if (this.outletForm["controls"].address.value === this.updateAddress) {
        this.editRestaurantOutlet();
      } else if (
        this.updateAddrssStatus &&
        this.outletForm["controls"].latitude.value !== "" &&
        this.outletForm["controls"].latitude.value !== null &&
        this.outletForm["controls"].longitude.value !== "" &&
        this.outletForm["controls"].longitude.value !== null
      ) {
        this.editRestaurantOutlet();
      } else if (
        this.outletForm["controls"].address.value &&
        this.updateAddrssStatus == false
      ) {
        this.toastr.errorToastr(
          "Please select a valid address from suggestions."
        );
      }
    }
  }

  editRestaurantOutlet() {
    if (this.outletForm.valid && this.submitted) {
      var data = {
        address: this.outletForm.controls["address"].value,
        latitude: this.outletForm.controls["latitude"].value,
        longitude: this.outletForm.controls["longitude"].value,
        restaurantId: this.outletForm.controls["restaurantId"].value,
        updateId: this.item._id,
      };
      var newArr = [];
      for (let i = 0; i < this.pointList.length; i++) {
        if (this.pointList.length - 1 === i) {
          newArr.push([this.pointList[i].lng, this.pointList[i].lat]);
          newArr.push([this.pointList[0].lng, this.pointList[0].lat]);
        } else {
          newArr.push([this.pointList[i].lng, this.pointList[i].lat]);
        }
      }
      data["geoLongLat"] = newArr;
      this.api.editRestaurantOutlet(data).subscribe((res) => {
        if (res["response"]["success"]) {
          this.toastr.successToastr(res["response"]["message"]);
          this.dialog.close("yes");
        } else {
          this.toastr.errorToastr(res["response"]["message"]);
          this.dialog.close("no");
        }
      });
    } else if (this.outletForm["controls"].address.value) {
      this.toastr.errorToastr(
        "Please select a valid address from suggestions."
      );
    }
  }

  handleAddress(address) {
    this.updateAddrssStatus = true;
    if (address.formatted_address) {
      var outlet = {
        address: address.formatted_address,
        latitude: address.geometry.location.lat(),
        longitude: address.geometry.location.lng(),
      };
      this.outletForm.controls["address"].setValue(address.formatted_address);
      this.outletForm.controls["latitude"].setValue(
        address.geometry.location.lat()
      );
      this.outletForm.controls["longitude"].setValue(
        address.geometry.location.lng()
      );
      this.latitude = address.geometry.location.lat();
      this.longitude = address.geometry.location.lng();
      this.lat = address.geometry.location.lat();
      this.lng = address.geometry.location.lng();
    }
  }

  markerDragEnds(event: MouseEvent) {
    this.latitude = event["coords"].lat;
    this.longitude = event["coords"].lng;
    this.lat = event["coords"].lat;
    this.lng = event["coords"].lng;
    this.getAddress(this.latitude, this.longitude);
    this.updateAddrssStatus = true;
  }
}

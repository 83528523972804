import { Component, OnInit } from '@angular/core';
import { ApiService } from '../cooldash/services/api/api.service';
import { CommonService } from '../cooldash/services/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
   newPassword: any;
   confirmPassword: any;
   isValidToken: boolean = false;
   flags = {
    isSubmit: false,
    isError: false
  };
  resetToken: string;
  verificationToken: string;
  endPoint: string;
  constructor(private api: ApiService,
    private comm: CommonService,
    private router: Router,
    private activeRoute: ActivatedRoute) {
      this.activeRoute.queryParams.subscribe(params => {
        params.adminForgotToken && (this.resetToken = params.adminForgotToken, this.endPoint = '/admin/forgotChangePassword')
        params.userForgotToken && (this.resetToken = params.userForgotToken, this.endPoint = '/user/forgotChangePassword')
        params.driverForgotToken && (this.resetToken = params.driverForgotToken, this.endPoint = '/driver/forgotChangePassword')
        params.userVerificationToken && (this.verificationToken = params.userVerificationToken, this.endPoint = '/user/verifyEmailLink', this.onSubmit())
      })
    }

  ngOnInit() {
    // this.api.checkToken(this.resetToken).subscribe(response => {
    //   if (response["response"]["success"]) {
    //     this.isValidToken = true;
    //   } else {
    //     this.isValidToken = false;
    //     this.comm.errorToast(response["response"]["message"]);
    //     this.router.navigateByUrl("/login");
    //   }
    // });
  }

  onSubmit() {
    this.flags.isSubmit = true;
    if(this.newPassword == this.confirmPassword){
      var data = {
        password : this.newPassword
      };
      this.verificationToken && (data['verificationToken'] = this.verificationToken, delete data['password'])
      this.resetToken && (data['resetToken'] = this.resetToken)
      this.api.resetPassword(data,this.endPoint).subscribe(response => {
        if (response["response"]["success"]) {
          this.comm.successToast(response["response"]["message"]);
          localStorage.clear();
          if(this.endPoint === '/admin/forgotChangePassword') {
            this.router.navigateByUrl("/login");
          } else {
            setTimeout(() => { window.close();}, 3000);
          }
        } else {
          this.flags.isSubmit = false;
          this.comm.errorToast(response["response"]["message"]);
          this.flags.isError = true;
        }
      });
    }else{
      this.comm.errorToast("New password and Confirm password must match !")
    }
   
  }


}
